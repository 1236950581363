@use '@angular/material' as mat;
@import 'ngx-owl-carousel-o/lib/styles/scss/owl.carousel';
@import 'ngx-owl-carousel-o/lib/styles/scss/owl.theme.default';

// Defina suas cores primária e secundária
$sua-cor-primaria: mat.m2-define-palette(mat.$m2-yellow-palette, 500);
$sua-cor-secundaria: mat.m2-define-palette(mat.$m2-indigo-palette, 500);

// Crie o objeto de tema com suas cores definidas
$seu-tema: mat.m2-define-light-theme((color: (primary: $sua-cor-primaria,
        accent: $sua-cor-secundaria,
        // opcionalmente, você também pode definir a cor de aviso (warn)
        // warn: $sua-cor-de-aviso,
      )));

// Inclua os estilos do tema para todos os componentes que você está usando em sua aplicação
@include mat.all-component-themes($seu-tema);

/* You can add global styles to this file, and also import other style files */
/* CSS Reset */
html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td {
  margin: 0;
  padding: 0;
  border: 0;
  outline: 0;
  font-size: 100%;
  vertical-align: baseline;
  background: transparent;
}

body {
  line-height: 1;
}

ol,
ul {
  list-style: none;
}

blockquote,
q {
  quotes: none;
}

blockquote:before,
blockquote:after,
q:before,
q:after {
  content: "";
  content: none;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

html {
  scroll-behavior: smooth;
}

html,
body {
  height: 100%;
  margin: 0;
}

.offcanvas-backdrop.show {
  opacity: 0.2 !important;
}

body {
  font-family: 'Montserrat', sans-serif;
  display: flex;
  flex-direction: column;
  overflow-x: hidden;
}

@media (max-width: 991px) {
  .container.main-container {
    max-width: 100%;
  }
}

@media (min-width: 2000px) {
  .container.main-container {
    // max-width: 1820px;
  }
}

app-root {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

.router-outlet {
  flex: 1;
}

footer {
  margin-top: auto;
}

.cursor-pointer {
  cursor: pointer;
}

app-spinner {
  z-index: 100;
  position: fixed;
  margin: 0 auto;
  width: 100%;
  height: 100%;
  top: 0;
  background: #fff;
  opacity: 0.5;
}

app-alert {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 3000 !important;
  /* Ensure it is above other elements */
  padding: 10px;
  box-sizing: border-box;
  /* Ensure padding doesn't affect width */
}

// --- FORM STYLE ---------------
.field-form-control {
  padding: .5em 0 .5em 0;

  label {
    font-size: small;

    &.required {
      &:after {
        content: "*";
        color: red;
      }
    }
  }
}

.form-control:disabled {
  cursor: not-allowed;
}

// --- COMMON STYLE ---------------
@media (max-width: 400px) {
  .fs-sm-xsmall {
    font-size: x-small;
  }
}

.fs-xsmall {
  font-size: x-small;
}

.fs-small {
  font-size: small;
}

// .password-forgot {
//   color: #E0C252;
//   font-weight: 300;
//   font-size: 14px;
// }
.logo-sou-mais-fc-page {
  img {
    width: 8rem;
    height: auto;
  }

}

.z-index11 {
  z-index: 11;
}

.justify-self-center {
  justify-self: center;
}

.custom-scrollbar {

  // For WebKit-based browsers
  &::-webkit-scrollbar {
    width: 12px; // Width of the scrollbar
  }

  &::-webkit-scrollbar-track {
    background: #f1f1f1; // Track color (the space behind the scrollbar)
  }

  &::-webkit-scrollbar-thumb {
    background-color: #c1c1c1; // Color of the scrollbar thumb
    border-radius: 6px; // Round the corners for a macOS-like style
    border: 3px solid #f1f1f1; // Adds space between the thumb and track for macOS feel
  }

  &::-webkit-scrollbar-thumb:hover {
    background-color: #a8a8a8; // Thumb color when hovered
  }

  // Optional styling for Firefox (using its proprietary scrollbar CSS)
  scrollbar-width: thin;
  scrollbar-color: #c1c1c1 #f1f1f1;
}

// --- CAROUSEL ---------------

.carousel-indicators,
.carousel-control-next,
.carousel-control-prev {
  z-index: 0;
}

.carousel-control-prev,
.carousel-control-next {
  background-color: #000000;
  border: 1px solid transparent;
  box-shadow: 0px 4px 4px 0px #00000040;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  top: 50%;
  transform: translateY(-50%);
  opacity: 100%;

  .carousel-control-prev-icon,
  .carousel-control-next-icon {
    width: 60%;
  }

  // Cor do ícone do botão next e prev
  span {
    filter: #FFFFFF;
  }
}


@media (min-width: 1800px) {

  .carousel-control-prev,
  .carousel-control-next {
    width: 60px;
    height: 60px;
  }
}

.owl-nav {
  display: none;
}

.carousel {
  margin-bottom: 5em;

  .owl-item {
    height: 45rem;
    border-radius: 10px;
    max-height: 225px;
  }
}

.back-buttom {
  position: absolute;
  right: 0;
  top: 0.5rem;
}

.ingressos {
  width: 100%;
  margin-top: 60px;

  h3 {
    font-size: 20px;
    font-weight: 600;
    line-height: 24.38px;
    color: #FFFFFF;
  }

  .owl-item {
    max-width: 30.9%;
    min-width: 319px;
    margin-top: 25px;
    border-radius: 15px;
    padding: 10px 20px;
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    background: linear-gradient(to bottom, rgba(90, 89, 107, 1), rgba(90, 89, 107, .85)), url("/assets/imagens/newspaper.png");
    background-size: cover;
    color: #FFFFFF;

    @media (max-width: 1200px) {
      max-width: 46.8%;
    }

    @media (max-width: 990px) {
      max-width: 62.8%;
      padding: 10px 10px;
    }

    @media (max-width: 767px) {
      max-width: 100%;
      padding: 10px 10px;
    }

    .header {
      display: flex;
      justify-content: space-between;
      align-items: flex-start;
      flex-direction: row;
      width: 100%;

      .nomeEvento {
        width: 40%;
      }

      .times {
        display: flex;
        flex-direction: row;
        align-items: center;

        .time {
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;
          padding: 0 10px;


          img {
            height: 20px;
            width: 20px;
          }

          p {
            margin-top: 2px;
            font-size: 8px;
            color: #FFFFFF;
            line-height: 9.75px;
            font-weight: 700;
          }
        }

        .x {
          font-size: 10px;
          color: #FFFFFF;
          line-height: 12.19px;
          font-weight: 600;
          margin: 0 10px;
        }
      }
    }

    .linha {
      display: flex;
      justify-content: space-between;
      margin-top: 17px;
    }

    .title {
      font-size: 8px;
      font-weight: 400;
      line-height: 9.75px;
    }

    .description {
      font-size: 12px;
      font-weight: 600;
      line-height: 14.63px;
    }

    .setor {
      font-size: 18px;
      font-weight: 500;
      line-height: 21.94px;
    }

    .content {
      padding: 20px 0 0;
      width: 100%;

      p {
        color: #191844;
        font-size: 14px;
        font-weight: 400;
        line-height: 17.07px;
      }
    }

    .valid {
      margin-top: 21px;
      display: flex;
      justify-content: end;

      p {
        color: #191844;
        font-size: 14px;
        font-weight: 400;
        line-height: 17.07px;
        font-style: italic;
      }
    }

  }

}
